import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(20.000000, 20.000000)">
        <path d="M 64.78125 -56.828125 C 64.78125 -52.242188 64.0625 -48.1875 62.625 -44.65625 C 61.195312 -41.125 59.113281 -38.148438 56.375 -35.734375 C 53.644531 -33.316406 50.289062 -31.472656 46.3125 -30.203125 C 42.34375 -28.941406 37.671875 -28.3125 32.296875 -28.3125 L 25.484375 -28.3125 L 25.484375 -2.265625 C 25.484375 -1.847656 25.34375 -1.46875 25.0625 -1.125 C 24.789062 -0.789062 24.335938 -0.519531 23.703125 -0.3125 C 23.078125 -0.101562 22.238281 0.0625 21.1875 0.1875 C 20.132812 0.3125 18.789062 0.375 17.15625 0.375 C 15.5625 0.375 14.226562 0.3125 13.15625 0.1875 C 12.082031 0.0625 11.226562 -0.101562 10.59375 -0.3125 C 9.96875 -0.519531 9.523438 -0.789062 9.265625 -1.125 C 9.015625 -1.46875 8.890625 -1.847656 8.890625 -2.265625 L 8.890625 -75.6875 C 8.890625 -77.664062 9.40625 -79.144531 10.4375 -80.125 C 11.46875 -81.113281 12.820312 -81.609375 14.5 -81.609375 L 33.75 -81.609375 C 35.675781 -81.609375 37.507812 -81.535156 39.25 -81.390625 C 41 -81.242188 43.09375 -80.925781 45.53125 -80.4375 C 47.976562 -79.957031 50.453125 -79.066406 52.953125 -77.765625 C 55.453125 -76.460938 57.582031 -74.8125 59.34375 -72.8125 C 61.113281 -70.820312 62.460938 -68.488281 63.390625 -65.8125 C 64.316406 -63.144531 64.78125 -60.148438 64.78125 -56.828125 Z M 47.4375 -55.625 C 47.4375 -58.488281 46.929688 -60.84375 45.921875 -62.6875 C 44.910156 -64.539062 43.664062 -65.910156 42.1875 -66.796875 C 40.71875 -67.679688 39.171875 -68.238281 37.546875 -68.46875 C 35.929688 -68.695312 34.265625 -68.8125 32.546875 -68.8125 L 25.484375 -68.8125 L 25.484375 -41.125 L 32.921875 -41.125 C 35.566406 -41.125 37.785156 -41.476562 39.578125 -42.1875 C 41.367188 -42.90625 42.832031 -43.90625 43.96875 -45.1875 C 45.101562 -46.46875 45.960938 -48 46.546875 -49.78125 C 47.140625 -51.570312 47.4375 -53.519531 47.4375 -55.625 Z M 47.4375 -55.625 "/>
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
